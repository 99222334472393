import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import MakeAppointment from "../components/makeAppointment"


const ServicesPage = (props) => {

  const data = useStaticQuery(graphql`
    query {
      services: file(relativePath: { eq: "services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            addressPrimaryLine
            city
            phone
            state
            zipCode
            name
            id
            phone
            node_locale
            url
            address
            location {
              lon
              lat
            }
          }
        }
      }
    }
  `)
  const locations = data.allContentfulLocation.edges

  return (
    <Layout>
      <SEO title="Our services " />
      <div className="mx-auto container font-sans flex lg:mb-4">
        <div className="mb-8 min-w-2xl pr-8 hidden lg:block lg:pt-12">
          <Img fluid={data.services.childImageSharp.fluid} alt="associations" className="h-32 lg:h-84 lg:max-w-4xl mx-auto" />
        </div>
        <div className="lg:pt-8">
          <div className="block mb-4 lg:min-w-2xl lg:pr-8 lg:hidden">
            <Img fluid={data.services.childImageSharp.fluid} alt="associations" className="h-32 lg:h-84 lg:max-w-4xl mx-auto" />
          </div>
          <h2 className="text-center lg:text-left uppercase text-xl mt-2 lg:mt-4 font-bold text-navy-500 font-thin mb-4 lg:mb-12">Our Services</h2>
          <ul className="list-disc text-navy-500 list-inside px-4 text-left mb-4 block lg:hidden">
            <li className="text-base mb-1 font-bold"><a href="#service-1">Comprehensive medical eye exams</a></li>
            <li className="text-base mb-1 font-bold"><a href="#service-2">Routine eye exams</a></li>
            <li className="text-base mb-1 font-bold"><a href="#service-3">Emergency office visits and after hours care</a></li>
            <li className="text-base mb-1 font-bold"><a href="#service-4">Specialty contact lens fitting</a></li>
            <li className="text-base mb-1 font-bold"><a href="#service-5">Medicaid exams and glasses dispensing</a></li>
            <li className="text-base mb-1 font-bold"><a href="#service-6">Low vision exams</a></li>
          </ul>

            <div className="flex lg:hidden justify-center mb-6 lg:mb-8 flex-col items-center">
              <Link to="/locations"
                className="font-sans w-64 py-2 tracking-wide radial-gradient-green rounded shadow flex  block cursor-pointer phone items-center justify-center mb-2">
                <svg className="w-8 h-8" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
                </svg>
                <span className="pr-4 text-white font-bold text-center">Call for your consultation</span>
              </Link>
              <MakeAppointment />
            </div>

          <p className="max-w-3xl mx-auto text-navy-500 px-4 lg:px-0 mb-4 text-center lg:text-left lg:mb-12" >
            At your visit we will perform a prescription for glasses, thoroughly check the outside and inside of the eye for any potential problems or eye diseases. If you wear contact lenses or are interested in them, the doctors can perform additional testing to determine the best contact lens prescription for your visual needs.
          </p>
          <div className="hidden lg:flex flex-col items-center lg:items-start justify-center mb-8">
            <Link to="/locations"
              className="font-sans w-64 py-2 tracking-wide radial-gradient-green rounded shadow flex  block cursor-pointer phone items-center justify-center mb-2">
              <svg className="w-8 h-8" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z" fill="white" />
              </svg>
              <span className="pr-4 text-white font-bold text-center">Call for your consultation</span>
            </Link>
            <MakeAppointment/>
          </div>
          <div className="mx-auto flex justify-center lg:justify-start text-white font-bold font-sans mb-8">
            <Link to="/insurances"
              className=" py-1 px-2 bg-navy-700 rounded shadow flex items-center block mr-2 text-center cursor-pointer phone justify-center">
              <span className="text-center">Insurances accepted</span>
            </Link>
            <Link to="/forms"
              className=" py-1 px-2 bg-navy-700 rounded shadow flex items-center block text-center cursor-pointer phone justify-center">
              <span className="text-center">Download forms</span>
            </Link>
          </div>
          <div className="lg:flex hidden justify-center lg:justify-start">
            {locations.map(({ node: location }) => (
              <Link className="flex items-end mr-5 " key={location.id} to={'/' + location.url} aria-label={location.name + ' U & M Family Eye Care'}>
                <div className="flex items-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="#D8E0E9" />
                  </svg>
                  <p className="text-navy-500 font-bold tracking-wide">{location.name}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-5xl lg:flex mb-4 lg:pt-4">
        <div className="text-navy-500 text-center max-w-md px-2 lg:px-0 lg:mb-4 lg:mx-auto">
        <div className="">
            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-1" className="mb-1 font-bold">Myopia Control Treatments:</h3>
                <p className="w-full">Myopia (nearsightedness) has become widespread among young people over the last 20 years due in large part to genetics and lifestyle choices. By 2050 more than 50% of our school-age population are expected to be myopic.  Leaving myopia unmanaged can contribute to severe eye health complications and sight-threatening conditions later in life including retinal detachment, myopic maculopathy, glaucoma, and even early cataracts.  Our skilled doctors can discuss many treatment options to prevent the further progression of myopia.</p>
              </div>
            </div>

            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-1" className="mb-1 font-bold">Low Vision Evaluations:</h3>
                <p className="w-full">Our doctors will do a thorough Medical Eye Exam and then work to evaluate your vision and lifestyle needs. Afterward, you will learn the best strategies to restore your ability to do the things you love. We offer a wide range of practical low vision devices, keeping in mind budget and lifestyle of the patient. We are committed to helping each of our low-vision patients achieve their goals of improved vision.</p>
              </div>
            </div>

            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-1" className="mb-1 font-bold">Comprehensive Medical Eye Exams:</h3>
                <p className="w-full">This exam is offered to our patients with significant medical conditions including, but not limited to; diabetes, high blood pressure, glaucoma, cataract and macular degeneration.</p>
              </div>
            </div>

            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-2" className="mb-1 font-bold">Routine Eye Exams:</h3>
                <p className="w-full">This includes a check up for glasses or contact lenses.</p>
              </div>
            </div>

            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-3" className="mb-1 font-bold">Emergency Office Visits and After Hours Care:</h3>
                <p className="w-full">This is offered to our patients with red eyes, infections, foreign materials in the eye and many other urgent eye care issues.</p>
              </div>
            </div>
        </div>
        <div className="">
            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-4" className="mb-1 font-bold">Specialty Contact lens Fitting:</h3>
                <p className="w-full">Our skilled doctors can fit patients with special needs including; keratoconus, bifocal, multifocal contacts and toric lenses.</p>
              </div>
            </div>

            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-5" className="mb-1 font-bold">Medicaid Exams and Glasses Dispensing:</h3>
                <p className="w-full">We have glasses available for all the Georgia Medicaid plans.</p>
              </div>
            </div>

            <div className="flex px-2 pb-4 lg:pb-8">
              <div className="w-full">
                <h3 id="service-6" className="mb-1 font-bold">Low Vision Exams:</h3>
                <p className="w-full">This exam is for visually impaired patients who require help with mobility and life style adjustments due to vision loss.</p>
              </div>
            </div>
        </div>
        </div>

      </div>

    </Layout>
  )
}

export default ServicesPage
